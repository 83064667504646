import React, { Component } from 'react'
import styles from './ToolBarItemComponent.module.css'

export class ToolBarItem extends Component {

  render () {
    return (
      <div className={styles.ToolBarItem} style={{
        height: 60,
        width: 60
      }}>
        <div style={{
          width: 50,
          height: 50
        }}>{this.props.icon}</div>
        <div style={{
          width: 50,
          height: 15,
          marginBottom: '5px'
        }}>{this.props.text}</div>
      </div>
    )
  }
}