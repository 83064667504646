import React, { Component } from 'react'
import styles from './MdEditorComponent.module.css'
import { connect } from 'react-redux'
import { editMarkdown } from '../../../redux/sync-actions'
import ReactMarkdownComponent from '../article-component/ReactMarkdownComponent'

const DEFAULT_SIZE = 500 //默认代码块高度

class MdEditorComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      textareaHeight: DEFAULT_SIZE
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize) //监听resize
  }

  handleResize = e => {

  }

  //修改内容状态
  handleInputMarkdown = e => {
    let previewHeight = this.preview.scrollHeight
    let textareaHeight = e.target.scrollHeight
    let height = Math.max(previewHeight, textareaHeight, DEFAULT_SIZE)
    this.setState({ textareaHeight: height })
    this.props.editMarkdown(e.target.value)
  }

  //当鼠标选中textarea时
  handleKeyPressMarkdown = e => {
    let previewHeight = this.preview.scrollHeight
    let textareaHeight = e.target.scrollHeight
    let height = Math.max(previewHeight, textareaHeight, DEFAULT_SIZE)
    //下面是自定义的特殊按键触发的效果
    if (e.keyCode === 9) {
      e.preventDefault()
      this.props.editMarkdown(this.props.markdown + '\t')
    } else if (e.keyCode === 13)
      return false
    else if (e.keyCode === 17) { //left ctrl
      this.setState({ textareaHeight: height })
    } else if (e.keyCode === 8) { //backspace
      this.setState({ textareaHeight: height })
    } else if (e.keyCode === 192) { // ~
      // e.preventDefault()
    }
  }

  render () {
    return (
      <div className={styles.container} style={{
        height: this.state.textareaHeight //设置默认高度
      }}>
        <div className={styles.input}>
          <textarea style={{ border: '0px' }} spellCheck={false} value={this.props.markdown}
                    onKeyDown={this.handleKeyPressMarkdown}
                    onChange={this.handleInputMarkdown}/>
        </div>
        <div ref={div => this.preview = div} className={styles.md}>
          <ReactMarkdownComponent content={this.props.markdown}/>
        </div>
      </div>
    )
  }
}

MdEditorComponent = connect(
  ({ $BlogState: { mdContent } }) => ({ markdown: mdContent }),
  { editMarkdown }
)(MdEditorComponent)

export default MdEditorComponent