import React, { Component } from 'react'
import styles from './ArticleListComponent.module.css'
import PropTypes from 'prop-types'
import { List } from 'antd'
import { TagTwoTone, ClockCircleTwoTone, MessageOutlined, EyeOutlined, LikeOutlined } from '@ant-design/icons'
import { changePage, jumpPage } from '../../base/function/window'
import { connect } from 'react-redux'
import { getBlogsAsync } from '../../../redux/async-actions'

const category = {
  'MEMORY': '追忆时光',
  'TECH': '技术分享',
  'ME': '关于”我“',
}

class ArticleListComponent extends Component {
  state = {
    loading: true,
    current: 1,
  }

  componentDidMount () {
    let { category, getBlogsAsync, pageSize } = this.props
    getBlogsAsync({ category: category, page: this.state.current, pageSize: pageSize }).then(() => {
      this.setState({ loading: false })
    })
  }

  onChange = e => {
    this.setState({ loading: true })
    let { category, getBlogsAsync, pageSize } = this.props
    getBlogsAsync({ category: category, page: e, pageSize: pageSize }).then(() => {
      this.setState({ loading: false, current: e })
    })
  }

  render () {
    let { pageSize, hideOnSinglePage, showQuickJumper } = this.props
    let { loading, current } = this.state
    let { total, blogs } = this.props.blogs
    return (
      <div className={styles.container}>
        <List
          locale={{ emptyText: '/(ㄒoㄒ)/~~目前还没有文章噢~赶紧催博主去写吧！' }}
          loading={loading}
          itemLayout="horizontal"
          dataSource={blogs}
          pagination={{
            pageSize: pageSize,
            hideOnSinglePage: hideOnSinglePage,
            showQuickJumper: showQuickJumper,
            total: total,
            current: current,
            onChange: this.onChange
          }}
          renderItem={item => (
            <List.Item>
              <div className={styles.article_card}>
                <div className={styles.left_img}>
                  <img src={item.imgUrl} height="144px" width="256px" alt="randyzhangの博客"/>
                </div>
                <div className={styles.right_content}>
                  <div className={styles.article_title}
                       onClick={() => jumpPage(`blog/${item.blogId}`)}>
                    {item.title}
                  </div>
                  <div className={styles.article_content}>
                    <span>{(item.content.length > 0 ? item.content.replace(/#/g, '').replace(/\*/g, '').replace(/`/g, '') : item.content).substr(0, 120)}...</span>
                    <span onClick={() => jumpPage(`blog/${item.blogId}`)}
                          className={styles.article_detail}>
                      [详情]
                    </span>
                  </div>
                  <div className={styles.article_other}>
                    <div className={styles.other_left}>
                      <TagTwoTone twoToneColor="#eb2f96"/>
                      <span className={styles.tag}
                            onClick={() => changePage(item.category === 'TECH' ? '/tech' : item.category === 'MEMORY' ? '/memory' : '/me')}>{category[item.category]}</span>
                      <ClockCircleTwoTone twoToneColor="#7ec1ff"/>
                      <span className={styles.time}>{item.releaseTime}</span>
                    </div>
                    <div className={styles.other_right}>
                      <MessageOutlined/><span className={styles.msg}>{item.msgNum}</span>
                      <EyeOutlined/><span className={styles.eye}>{item.eyeNum}</span>
                      <LikeOutlined/><span className={styles.like}>{item.likeNum}</span>
                    </div>
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
    )
  }
}

ArticleListComponent.propTypes = {
  category: PropTypes.string.isRequired,
  hideOnSinglePage: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  showQuickJumper: PropTypes.number
}

ArticleListComponent = connect(
  ({ $BlogState: { blogs } }) => ({ blogs }),
  { getBlogsAsync }
)(ArticleListComponent)

export default ArticleListComponent