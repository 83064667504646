import React, { Component } from 'react'
import PropTypes from 'prop-types'

class LineIntroductionComponent extends Component {

  render () {
    let { title, component, borderColor } = this.props
    return (
      <div style={{
        border: 'inset',
        borderColor: borderColor,
        borderWidth: '0px 0px 5px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '5px' }}>{title}</div>
        <div>{component}</div>
      </div>
    )
  }
}

LineIntroductionComponent.propTypes = {
  borderColor: PropTypes.string.isRequired,
  component: PropTypes.any,
  title: PropTypes.string.isRequired
}

export default LineIntroductionComponent