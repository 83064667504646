import React, { Component } from 'react'
import styles from './MoodLeftComponent.module.css'
import LineIntroductionComponent from '../common-component/LineIntroductionComponent'
import MoodListComponent from './MoodListComponent'
// eslint-disable-next-line
import LeaveMoodComponent from './LeaveMoodComponent'
import { Divider } from 'antd'
import { connect } from 'react-redux'
import { getMoodTotalAsync } from '../../../redux/async-actions'

class MoodLeftComponent extends Component {

  componentDidMount () {
    let { getMoodTotalAsync } = this.props
    getMoodTotalAsync()
  }

  render () {
    let { moodTotal } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.line_introduction}>
          <LineIntroductionComponent title="心情随笔" borderColor="#FF6347"/>
        </div>
        {
          // accessToken&&role==='ROOT'&&
          <div className={styles.leave_mood}>
            {/*<LeaveMoodComponent/>*/}
            <Divider orientation="left" type="horizontal">随笔({moodTotal}条)</Divider>
          </div>
        }
        <div className={styles.moon_list}>
          <MoodListComponent hideOnSinglePage={true} pageSize={15} showQuickJumper={false}/>
        </div>
      </div>
    )
  }
}

MoodLeftComponent = connect(
  ({ $MoodState: { moodTotal } }) => ({ moodTotal }),
  { getMoodTotalAsync }
)(MoodLeftComponent)

export default MoodLeftComponent