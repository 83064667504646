import React, { Component } from 'react'
import styles from './MoodListComponent.module.css'
import PropTypes from 'prop-types'
import { List } from 'antd'
import { CalendarTwoTone } from '@ant-design/icons'
import { connect } from 'react-redux'
import { getMoodsAsync } from '../../../redux/async-actions'

class MoodListComponent extends Component {

  state = {
    loading: true,
    current: 1,
  }

  componentDidMount () {
    let { getMoodsAsync, pageSize } = this.props
    getMoodsAsync({ page: this.state.current, pageSize }).then(
      () => {this.setState({ loading: false })}
    )
  }

  onChange = e => {
    this.setState({ loading: true })
    let { getMoodsAsync, pageSize } = this.props
    getMoodsAsync({ page: e, pageSize }).then(
      () => {this.setState({ loading: false, current: e })}
    )
  }

  render () {
    let { pageSize, hideOnSinglePage, showQuickJumper } = this.props
    let { moods, total } = this.props.moods
    let { loading, current } = this.state
    return (
      <div className={styles.container}>
        <List
          locale={{ emptyText: '/(ㄒoㄒ)/~~目前还没有心情记录噢~' }}
          itemLayout="horizontal"
          dataSource={moods}
          pagination={{
            pageSize: pageSize,
            hideOnSinglePage: hideOnSinglePage,
            showQuickJumper: showQuickJumper,
            total: total,
            current: current,
            onChange: this.onChange
          }}
          loading={loading}
          renderItem={item => (
            <List.Item>
              <div className={styles.content_container}>
                <div className={styles.time}><CalendarTwoTone/>
                  <span className={styles.time_content}>{item.time}</span>
                </div>
                <div className={styles.content}>{item.content}</div>
              </div>
            </List.Item>
          )}
        />
      </div>
    )
  }
}

MoodListComponent.propTypes = {
  hideOnSinglePage: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  showQuickJumper: PropTypes.number
}

MoodListComponent = connect(
  ({ $MoodState: { moods } }) => ({ moods }),
  { getMoodsAsync }
)(MoodListComponent)

export default MoodListComponent