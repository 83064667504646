import { ToolBarItem } from './ToolBarItemComponent'
import {
  BellOutlined,
  BoldOutlined,
  CodeOutlined,
  ExportOutlined,
  HighlightOutlined,
  ImportOutlined,
  ItalicOutlined,
  LinkOutlined,
  MenuOutlined,
  OrderedListOutlined,
  PictureOutlined,
  PlaySquareOutlined,
  RedoOutlined,
  SaveOutlined,
  StrikethroughOutlined,
  TableOutlined,
  UndoOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import { Divider } from 'antd'
import React from 'react'

const LineDiv = () =>
  <Divider type="vertical" style={{
    height: 75,
    margin: '0 15px'
  }}/>

export function ToolBoxComponent (props) {
  const fontSize = 24
  return <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: '1px solid #e9e9e9',
    backgroundColor: '#fff',
  }}>
    <ToolBarItem icon={<BoldOutlined style={{ fontSize }}/>} text="加粗"/>
    <ToolBarItem icon={<ItalicOutlined style={{ fontSize }}/>} text="斜体"/>
    <ToolBarItem icon={<HighlightOutlined style={{ fontSize }}/>} text="标题"/>
    <ToolBarItem icon={<StrikethroughOutlined style={{ fontSize }}/>} text="删除线"/>
    <LineDiv/>
    <ToolBarItem icon={<UnorderedListOutlined style={{ fontSize }}/>} text="无序"/>
    <ToolBarItem icon={<OrderedListOutlined style={{ fontSize }}/>} text="有序"/>
    <ToolBarItem icon={<BellOutlined style={{ fontSize }}/>} text="代办"/>
    <ToolBarItem icon={<CodeOutlined style={{ fontSize }}/>} text="代码块"/>
    <LineDiv/>
    <ToolBarItem icon={<PictureOutlined style={{ fontSize }}/>} text="图片"/>
    <ToolBarItem icon={<PlaySquareOutlined style={{ fontSize }}/>} text="视频"/>
    <LineDiv/>
    <ToolBarItem icon={<TableOutlined style={{ fontSize }}/>} text="表格"/>
    <ToolBarItem icon={<LinkOutlined style={{ fontSize }}/>} text="超链接"/>
    <ToolBarItem icon={<MenuOutlined style={{ fontSize }}/>} text="摘要"/>
    <LineDiv/>
    <ToolBarItem icon={<ImportOutlined style={{ fontSize }}/>} text="导入"/>
    <ToolBarItem icon={<ExportOutlined style={{ fontSize }}/>} text="导出"/>
    <LineDiv/>
    <ToolBarItem icon={<SaveOutlined style={{ fontSize }}/>} text="保存"/>
    <ToolBarItem icon={<UndoOutlined style={{ fontSize }}/>} text="撤销"/>
    <ToolBarItem icon={<RedoOutlined style={{ fontSize }}/>} text="重做"/>
  </div>
}
