import React, { Component } from 'react'
import { List } from 'antd'
import styles from './CurrentPaperComponent.module.css'
import { HeartTwoTone } from '@ant-design/icons'
import { jumpPage } from '../../base/function/window'
import { connect } from 'react-redux'
import { getCurrentBlogsAsync } from '../../../redux/async-actions'

class CurrentPaperComponent extends Component {

  state = {
    loading: true,
  }

  componentDidMount () {
    this.props.getCurrentBlogsAsync({ category: undefined, page: 1, pageSize: 4 }).then(
      () => {this.setState({ loading: false })}
    )
  }

  render () {
    let { blogs } = this.props.blogs
    let { loading } = this.state
    return (
      <div>
        <List
          itemLayout="horizontal"
          dataSource={blogs}
          split={false}
          size="small"
          loading={loading}
          renderItem={item => (
            <List.Item>
              <div className={styles.name} onClick={() => jumpPage(`blog/${item.blogId}`)}><HeartTwoTone
                twoToneColor="#eb2f96"/>&nbsp;&nbsp;{item.title}</div>
            </List.Item>
          )}
        />
      </div>
    )
  }
}

CurrentPaperComponent = connect(
  ({ $BlogState: { currentBlogs } }) => ({ blogs: currentBlogs }),
  { getCurrentBlogsAsync }
)(CurrentPaperComponent)

export default CurrentPaperComponent