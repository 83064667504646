import React, { Component } from 'react'
import styles from './AboutMePage.module.css'
import InformationBarComponent from '../component/home-component/InformationBarComponent'
import MeLeftComponent from '../component/me-component/MeLeftComponent'

class AboutMePage extends Component {

  componentDidMount () {
    document.title = 'RZBLOG-关于“我”'
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <MeLeftComponent/>
        </div>
        <div className={styles.right}>
          <InformationBarComponent/>
        </div>
      </div>
    )
  }
}

export default AboutMePage