import React, { Component } from 'react'
import { Button } from 'antd'

class TestPage extends Component {

  handleJump = url => {
    window.open(url)
  }

  render () {
    return (
      <div>
        <Button onClick={() => this.handleJump('https://randyzhang.love')}>跳转到图表页面</Button>
      </div>
    )
  }
}

export default TestPage