import React, { Component } from 'react'
import NavMenu from './NavMenu'

class HomeNavMenu extends Component {
  render () {
    return (
      <div>
        <NavMenu leftList={[
          { key: 'home', name: '个人主页', link: '/home', disabled: false },
          { key: 'mood', name: '心情随笔', link: '/mood', disabled: false },
          { key: 'memory', name: '追忆时光', link: '/memory', disabled: false },
          { key: 'tech', name: '技术分享', link: '/tech', disabled: false },
          { key: 'msg', name: '访客留言', link: '/msg', disabled: false },
          { key: 'me', name: '关于”我“', link: '/me', disabled: false },
        ]}
        />
      </div>
    )
  }
}

export default HomeNavMenu