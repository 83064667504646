import PropTypes from 'prop-types'
import React from 'react'

function MainLayout (props) {
  return (
    <div>
      {props.navMenu}
      <div style={{
        width: '1080px',
        minHeight: '782px',
        margin: '0 auto',
        padding: '70px 10px 24px 10px'
      }}>
        {props.children}
      </div>
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node,
  navMenu: PropTypes.node
}

export default MainLayout
