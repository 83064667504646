import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ReactMarkdownComponent.module.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { prism } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import remarkToc from 'remark-toc'
import remarkGemoji from 'remark-gemoji'

import './katex/katex.css'

class ReactMarkdownComponent extends Component {

  render () {
    let { content } = this.props
    return (
      <div className={styles.container}>
        <ReactMarkdown
          children={content}
          remarkPlugins={[remarkGfm, remarkMath, remarkToc,remarkGemoji]}
          rehypePlugins={[rehypeRaw,rehypeKatex]}
          components={{
            code ({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '')
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, '')}
                  style={prism}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  <span className={styles.code}>{children}</span>
                </code>
              )
            },
            h1: ({ node, ...props }) => {
              let init = props.children[0]
              let headline = ''
              for (let i = 0; i < init.length; i++) {
                if (init[i] === ' ') headline += '-'
                else if (init[i] !== '.' && init[i] !== '<' && init[i] !== '>') headline += init[i]
              }
              return (
                // eslint-disable-next-line
                <div>
                  {/*eslint-disable-next-line*/}
                  <a name={headline.toLowerCase()}/>
                  {/*eslint-disable-next-line*/}
                  <h1 {...props}/>
                </div>
              )
            },
            h2: ({ node, ...props }) => {
              let init = props.children[0]
              let headline = ''
              for (let i = 0; i < init.length; i++) {
                if (init[i] === ' ') headline += '-'
                else if (init[i] !== '.' && init[i] !== '<' && init[i] !== '>') headline += init[i]
              }
              return (
                <div>
                  {/*eslint-disable-next-line*/}
                  <a name={headline.toLowerCase()}/>
                  {/*eslint-disable-next-line*/}
                  <h2 {...props}/>
                </div>
              )
            },
            h3: ({ node, ...props }) => {
              let init = props.children[0]
              let headline = ''
              for (let i = 0; i < init.length; i++) {
                if (init[i] === ' ') headline += '-'
                else if (init[i] !== '.' && init[i] !== '<' && init[i] !== '>') headline += init[i]
              }
              return (
                <div>
                  {/*eslint-disable-next-line*/}
                  <a name={headline.toLowerCase()}/>
                  {/*eslint-disable-next-line*/}
                  <h3 {...props}/>
                </div>
              )
            },
            h4: ({ node, ...props }) => {
              let init = props.children[0]
              let headline = ''
              for (let i = 0; i < init.length; i++) {
                if (init[i] === ' ') headline += '-'
                else if (init[i] !== '.' && init[i] !== '<' && init[i] !== '>') headline += init[i]
              }
              return (
                <div>
                  {/*eslint-disable-next-line*/}
                  <a name={headline.toLowerCase()}/>
                  {/*eslint-disable-next-line*/}
                  <h4 {...props}/>
                </div>
              )
            }
          }}
        />
      </div>
    )
  }
}

ReactMarkdownComponent.propTypes = {
  content: PropTypes.string.isRequired
}

export default ReactMarkdownComponent