export const initBlogState = {
  mdContent: `# Markdown Online Editor
## Subtitle

common content

### Little title

    Code Block
  
### Code Syntax Highlight
~~~js
console.log('hello randyzhang')
~~~
`,
  blogs: [],
  currentBlogs: [],
  blogContent: '',
  comments: [],
  searchBlogs: [],
}

export const initMsgState = {
  msgs: [],
  msgTotal: 0,
}

export const initMoodState = {
  moods: [],
  moodTotal: 0,
}

export const initDataState = {
  uv:'',
}
