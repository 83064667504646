import React, { Component } from 'react'
import styles from './BlogInfoSettingComponent.module.css'
import { Radio, Input, Form, Button, Space, message } from 'antd'
import { connect } from 'react-redux'
import { releaseBlogAsync } from '../../../redux/async-actions'
import { formatDate } from '../../base/function/formate'
import { isDevelop } from '../../base/function/prompt'

class BlogInfoSettingComponent extends Component {

  state = {
    isFinish: true
  }

  onFinish = e => {
    let { releaseBlogAsync, markdown } = this.props
    let { title, category } = e
    releaseBlogAsync({
      title,
      author: 'randyzhang',
      content: markdown,
      category,
      releaseTime: formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      lastModifyTime: formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      imgUrl: 'https://www.randyzhang.com/img/img1.png'
    }).then(() => {
      message.success('发布成功')
    }, () => {
      message.warning('发布失败')
    })
  }

  render () {
    return (
      <div className={styles.container}>
        <Form
          onFinish={this.onFinish}
        >
          <Form.Item label="标题" name="title">
            <Input placeholder="请输入文章标题" allowClear/>
          </Form.Item>
          <div className={styles.category}>
            <Form.Item label="类别" name="category">
              <Radio.Group>
                <Radio.Button value="MEMORY">追忆时光</Radio.Button>
                <Radio.Button value="TECH">技术分享</Radio.Button>
                <Radio.Button value="ME">关于“我”</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <div className={styles.left}>
              <Space>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={this.state.isFinish} onClick={()=>{isDevelop()}}>保存</Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" danger disabled={!this.state.isFinish}>发布</Button>
                </Form.Item>
              </Space>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

BlogInfoSettingComponent = connect(
  ({ $BlogState: { mdContent } }) => ({ markdown: mdContent }),
  { releaseBlogAsync }
)(BlogInfoSettingComponent)

export default BlogInfoSettingComponent