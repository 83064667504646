import React from 'react'
import PropTypes from 'prop-types'

function ContentBlockComponent (props) {
  return (
    <div style={{
      width: '100%',
      margin: '0px 0px 10px 0px',
    }}>
      <div style={{
        fontSize: '20px',
        padding: '5px 5px',
        borderColor: '#e6e6e6',
        borderStyle: 'solid',
        borderWidth: '0px 0px 1px 0px',
      }}>
        {props.title}
      </div>
      <div style={{
        padding: '10px 5px',
      }}>
        {props.children}
      </div>
    </div>
  )
}

ContentBlockComponent.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired
}

export default ContentBlockComponent