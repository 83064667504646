import React, { Component } from 'react'
import styles from './RegisterPage.module.css'
import { Button, Form, Input, Spin } from 'antd'
import { LoadingOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { registerAsync, verifyEmailAsync } from '../../../redux/async-actions'

class RegisterPage extends Component {

  state = {
    loading: false,
    registerSuccess: false,
  }

  handleSendVerifyCode = () => {
    const DEFAULT_INTER = 60000
    const email = document.getElementById('email').value
    this.props.verifyEmailAsync(email).then(() => {
      this.setState({ loading: true })
      this.timer = setTimeout(() => {
        this.setState({ loading: false })
      }, DEFAULT_INTER)
    })
  }

  render () {
    let { loading } = this.state
    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <Form name="register" onFinish={this.handleFinish}>
            <Form.Item name="username">
              <Input prefix={<UserOutlined/>} placeholder="请输入用户名"/>
            </Form.Item>
            <Form.Item name="password">
              <Input.Password prefix={<LockOutlined/>} placeholder="请输入密码"/>
            </Form.Item>
            <Form.Item name="confirmPassword" dependencies={['password']} hasFeedback rules={[
              {
                required: true,
                message: '请确认你的密码！'
              },
              ({ getFieldValue }) => ({
                validator (_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('两次密码不一致！')
                }
              })
            ]}>
              <Input.Password prefix={<LockOutlined/>} placeholder="确认密码"/>
            </Form.Item>
            <Form.Item name="email">
              <Input id="email" prefix={<MailOutlined/>} placeholder="请输入邮箱"/>
            </Form.Item>
            <Form.Item name="verifyCode">
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row'
              }}>
                <Input placeholder="邮箱验证码"/>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>} spinning={this.state.loading}>
                  <Button type="primary"
                          onClick={this.handleSendVerifyCode}>{loading === true ? '已发送验证码' : '发送邮箱验证码'}</Button>
                </Spin>
              </div>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>注册</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
}

RegisterPage = connect(
  // eslint-disable-next-line
  ({}) => ({}),
  { registerAsync, verifyEmailAsync }
)(RegisterPage)

export default RegisterPage
