import React, { Component } from 'react'
import styles from './MsgPage.module.css'
import InformationBarComponent from '../component/home-component/InformationBarComponent'
import MsgLeftComponent from '../component/msg-component/MsgLeftComponent'

class MsgPage extends Component {

  componentDidMount () {
    document.title = 'RZBLOG-访客留言'
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <MsgLeftComponent/>
        </div>
        <div className={styles.right}>
          <InformationBarComponent/>
        </div>
      </div>
    )
  }
}

export default MsgPage