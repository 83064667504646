import { getInit, postInit, putInit, query, request } from '../api/http'
import {
  set_blog_content,
  set_blog_like,
  set_blogs,
  set_comments,
  set_current_blogs,
  set_moods,
  set_msgs,
  update_msg_num,
  set_msg_total, set_mood_total, set_msg_like, set_blog_search, set_uv
} from './sync-actions'
import { asyncResponseHandler } from './base'

/**
 * 注册
 * @param username 用户名
 * @param password 密码
 * @param email 邮箱
 * @param code 验证码
 * @returns {(function(*): Promise<void>)|*}
 */
export const registerAsync = ({ username, password, email, code }) =>
  async dispatch => {
    const response = await request('/api/register', postInit({ username, password, email, code }))
    await asyncResponseHandler(response)
  }

/**
 * 发送验证邮件
 * @param email 邮件地址
 * @returns {(function(*): Promise<void>)|*}
 */
export const verifyEmailAsync = email =>
  async dispatch => {
    const response = await request('/api/register/verify', postInit({ email }))
    await asyncResponseHandler(response)
  }

/**
 * 获取博客列表
 * @param category 博客种类，MEMORY  TECH
 * @param page 页码
 * @param pageSize 页面大小
 * @returns {function(*): Promise<void>}
 */
export const getBlogsAsync = ({ category, page, pageSize }) =>
  async dispatch => {
    const response = await request(`/api/blogs?${query({ category, page, pageSize })}`, getInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_blogs(data))
  }

/**
 * 获取最近博客列表
 * @param category 博客种类，MEMORY  TECH
 * @param page 页码
 * @param pageSize 页面大小
 * @returns {function(*): Promise<void>}
 */
export const getCurrentBlogsAsync = ({ category, page, pageSize }) =>
  async dispatch => {
    const response = await request(`/api/blogs?${query({ category, page, pageSize })}`, getInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_current_blogs(data))
  }

/**
 * 获取博客信息
 * @param blogId 博客Id
 * @returns {function(*): Promise<void>}
 */
export const getBlogContentAsync = ({ blogId }) =>
  async dispatch => {
    const response = await request(`/api/blog/${blogId}`, getInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_blog_content(data))
  }

/**
 * 获取留言
 * @param isPublic 留言是否为公开
 * @param page 页码
 * @param pageSize 页面大小
 * @returns {function(*): Promise<void>}
 */
export const getMsgsAsync = ({ isPublic, page, pageSize }) =>
  async dispatch => {
    const response = await request(`/api/msgs?${query({ isPublic, page, pageSize })}`, getInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_msgs(data))
  }

/**
 * 用户留言
 * @param userId 用户Id
 * @param username 用户名
 * @param city 城市名
 * @param content 留言内容
 * @param likeNum 点赞数
 * @param dislike 踩数
 * @param time 留言时间
 * @param isPublic 是否公开
 * @returns {function(*): Promise<void>}
 */
export const leaveMsgAsync = ({ userId, username, city, content, likeNum, dislike, time, isPublic }) =>
  async dispatch => {
    const response = await request(`/api/msg`, postInit({
      userId,
      username,
      city,
      content,
      likeNum,
      dislike,
      time,
      isPublic
    }))
    await asyncResponseHandler(response)
    // dispatch(set_msgs({}))
  }

/**
 * 获取留言总数
 * @returns {(function(*): Promise<void>)|*}
 */
export const getMsgTotalAsync = ({ isPublic }) =>
  async dispatch => {
    const response = await request(`/api/msg/total?${query({ isPublic })}`, getInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_msg_total(data))
  }

/**
 * 获取心情随笔
 * @returns {function(*): Promise<void>}
 */
export const getMoodsAsync = ({ page, pageSize }) =>
  async dispatch => {
    const response = await request(`/api/moods?${query({ page, pageSize })}`, getInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_moods(data))
  }

/**
 * 记录心情随笔
 * @param time 时间
 * @param content 内容
 * @returns {function(*): Promise<void>}
 */
export const leaveMoodAsync = ({ time, content }) =>
  async dispatch => {
    const response = await request('/api/mood', postInit({ time, content }))
    await asyncResponseHandler(response)
  }

/**
 * 获取心情总数
 * @returns {(function(*): Promise<void>)|*}
 */
export const getMoodTotalAsync = () =>
  async dispatch => {
    const response = await request('/api/mood/total', getInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_mood_total(data))
  }

/**
 * 发布博客
 * @param title 文章标题
 * @param author 作者
 * @param content 内容
 * @param category 类别
 * @param releaseTime 发布时间
 * @param lastModifyTime 最后修改时间
 * @param imgUrl 图片url
 * @returns {function(*): Promise<void>}
 */
export const releaseBlogAsync = ({ title, author, content, category, releaseTime, lastModifyTime, imgUrl }) =>
  async dispatch => {
    const response = await request('/api/blog', postInit({
      title,
      author,
      content,
      category,
      releaseTime,
      lastModifyTime,
      imgUrl,
      msgNum: 0,
      eyeNum: 0,
      likeNum: 0,
      watch: 0
    }))
    await asyncResponseHandler(response)
  }

/**
 * 博客点赞
 * @param blogId 博客id
 * @returns {function(*): Promise<void>}
 */
export const likeBlogAsync = ({ blogId }) =>
  async dispatch => {
    const response = await request(`/api/blog/${blogId}`, putInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_blog_like(data))
  }

/**
 * 留言点赞
 * @param msgId
 * @returns {function(*): Promise<void>}
 */
export const likeMsgAsync = ({ msgId }) =>
  async dispatch => {
    const response = await request(`/api/msg/like/${msgId}`, putInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_msg_like(data))
  }

/**
 * 博客留言
 * @param userId 用户id
 * @param username 用户名
 * @param city 城市
 * @param time 时间
 * @param content 内容
 * @param likeNum 点赞人数
 * @param dislikeNum 踩人数
 * @param isPublic 是否公开
 * @param blogId 博客id
 * @returns {function(*): Promise<void>}
 */
export const releaseCommentAsync = ({ userId, username, city, time, content, likeNum, dislikeNum, isPublic, blogId }) =>
  async dispatch => {
    const response = await request(`/api/comment`, postInit({
      userId,
      username,
      city,
      time,
      content,
      likeNum,
      dislikeNum,
      isPublic,
      blogId
    }))
    let data = await asyncResponseHandler(response)
    if (response.code === 1) {
      dispatch(update_msg_num(data))
    }
  }

/**
 * 获取博客留言列表
 * @param blogId 博客id
 * @param isPublic 是否公开
 * @returns {function(*): Promise<*>}
 */
export const getCommentsAsync = ({ blogId, isPublic }) =>
  async dispatch => {
    const response = await request(`/api/comment/${blogId}?${query({ isPublic })}`, getInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_comments(data))
  }

/**
 * 搜索功能
 * @param keyword 关键词
 * @param type 类型，目前仅支持BLOG
 * @param page 页码，从1开始
 * @param pageSize 每页显示数量
 * @returns {(function(*): Promise<void>)|*}
 */
export const searchAsync = ({ keyword, type, page, pageSize }) =>
  async dispatch => {
    const response = await request(`/api/search?${query({ keyword, type, page, pageSize })}`, getInit())
    let data = await asyncResponseHandler(response)
    if (type === 'BLOG') {
      dispatch(set_blog_search(data))
    }
  }

/**
 * 获取访问数据
 * @param startTime 开始时间
 * @param endTime 结束时间
 * @returns {(function(*): Promise<void>)|*}
 */
export const getUVAsync = ({ startTime, endTime }) =>
  async dispatch => {
    const response = await request(`/api/data/uv?${query({ startTime, endTime })}`, getInit())
    let data = await asyncResponseHandler(response)
    dispatch(set_uv(data))
  }
