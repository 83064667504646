import React, { Component } from 'react'
import styles from './SearchContentComponent.module.css'
import SearchBlogListComponent from './SearchBlogListComponent'
import PropTypes from 'prop-types'

class SearchContentComponent extends Component {

  render () {
    let {keyword} = this.props
    return (
      <div className={styles.container}>
        <SearchBlogListComponent hideOnSinglePage={true} pageSize={8} keyword={keyword}/>
      </div>
    )
  }
}

SearchContentComponent.propTypes = {
  keyword: PropTypes.bool.isRequired
}

export default SearchContentComponent
