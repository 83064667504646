import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import {
  $BlogState,
  $LoginState,
  $GlobalState,
  $MoodState,
  $MsgState, $DataState,
} from './reducers'

const store = createStore(
  combineReducers({
    $GlobalState,
    $LoginState,
    $BlogState,
    $MsgState,
    $MoodState,
    $DataState,
  }),
  composeWithDevTools(applyMiddleware(thunk))
)

export default store
