import React, { Component } from 'react'
import ContentBlockComponent from '../md-component/ContentBlockComponent'
import SubscribeMeComponent from '../common-component/SubscribeMeComponent'
import FriendshipLinkComponent from '../common-component/FriendshipLinkComponent'
import CurrentPaperComponent from '../common-component/CurrentPaperComponent'
import WebInfoComponent from '../common-component/WebInfoComponent'

const blockList = [
  {
    title: '关注我',
    component: <SubscribeMeComponent/>,
  },
  {
    title: '最新文章',
    component: <CurrentPaperComponent/>,
  },
  {
    title: '友情链接',
    component: <FriendshipLinkComponent/>,
  },
  {
    title: '网站数据',
    component: <WebInfoComponent/>
  }
]

class InformationBarComponent extends Component {
  render () {
    return (
      <div>
        {
          blockList.map(item => (
            <ContentBlockComponent title={item.title}>
              {item.component}
            </ContentBlockComponent>
          ))
        }
      </div>
    )
  }
}

export default InformationBarComponent
