import {
  EDIT_MARKDOWN,
  LOGIN,
  SET_BLOG_CONTENT, SET_BLOG_LIKE, SET_BLOG_SEARCH,
  SET_BLOGS, SET_COMMENTS,
  SET_CURRENT_BLOGS, SET_MOOD_TOTAL,
  SET_MOODS, SET_MSG_LIKE, SET_MSG_TOTAL,
  SET_MSGS, SET_UV, UPDATE_MSG_NUM
} from './action-types'

export const actionFactory = (type, data = undefined) => ({ type, data })

export const login = data => actionFactory(LOGIN, data)

/**
 * 博客相关同步操作
 * @param data
 * @returns {{data: undefined, type: *}}
 */
export const editMarkdown = data => actionFactory(EDIT_MARKDOWN, data)
export const set_blogs = data => actionFactory(SET_BLOGS, data)
export const set_current_blogs = data => actionFactory(SET_CURRENT_BLOGS, data)
export const set_blog_content = data => actionFactory(SET_BLOG_CONTENT, data)
export const set_blog_like = data => actionFactory(SET_BLOG_LIKE, data)
export const set_comments = data => actionFactory(SET_COMMENTS, data)
export const set_blog_search = data => actionFactory(SET_BLOG_SEARCH, data)
export const update_msg_num = data => actionFactory(UPDATE_MSG_NUM, data)

/**
 * 留言相关同步操作
 * @param data
 * @returns {{data: undefined, type: *}}
 */
export const set_msgs = data => actionFactory(SET_MSGS, data)
export const set_msg_total = data => actionFactory(SET_MSG_TOTAL, data)
export const set_msg_like = data => actionFactory(SET_MSG_LIKE, data)

/**
 * 心情随笔相关操作
 * @param data
 * @returns {{data: undefined, type: *}}
 */
export const set_moods = data => actionFactory(SET_MOODS, data)
export const set_mood_total = data => actionFactory(SET_MOOD_TOTAL, data)

/**
 * 数据相关操作
 * @param data
 * @returns {{data: undefined, type}}
 */
export const set_uv = data => actionFactory(SET_UV,data)
