import React, { Component } from 'react'
import { Avatar, Button, Comment, Divider, Form, Input, List, message } from 'antd'
import PropTypes from 'prop-types'
import { formatDate } from '../../base/function/formate'
import head1 from '../../../assets/img/head2.jpg'
import head2 from '../../../assets/img/head3.jpg'
import styles from './MessageCommonComponent.module.css'
import { DislikeOutlined, LikeTwoTone } from '@ant-design/icons'
import ReactMarkdownComponent from '../article-component/ReactMarkdownComponent'
import { connect } from 'react-redux'
import { getMsgsAsync, getMsgTotalAsync, leaveMsgAsync, likeMsgAsync } from '../../../redux/async-actions'

const Editor = ({ onChangeValue, onChangeUsername, onSubmit, submitting, value, username }) => (
  <div className={styles.editor}>
    <Form.Item>
      <Input.TextArea rows={4} onChange={onChangeValue} value={value}
                      maxLength={255} showCount allowClear
                      placeholder="请输入留言内容，不超过255字，换行需空一行"/>
    </Form.Item>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}>
      <Form.Item label="昵称" required style={{ width: '40%' }}>
        <Input value={username} maxLength={20} placeholder="请输入您要留下的昵称" onChange={onChangeUsername}/>
      </Form.Item>
      {/*<Form.Item label="邮箱" style={{ width: '50%' }}>*/}
      {/*  <Input value={email} maxLength={50} placeholder="邮箱不会被公开" onChange={onChangeEmail}/>*/}
      {/*</Form.Item>*/}
    </div>
    <div>
      <Form.Item style={{
        float: 'right',
      }}>
        <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
          畅言一下
        </Button>
      </Form.Item>
    </div>
  </div>
)

class MessageCommonComponent extends Component {
  state = {
    submitting: false,
    value: '',
    username: '',
    loading: true,
    current: 1,
    isPublic: true,
    isLike: false,
    isDislike: false,
    likeColor: '#bfbfbf',
  }

  componentDidMount () {
    let { getMsgTotalAsync, getMsgsAsync, pageSize } = this.props
    getMsgTotalAsync({ isPublic: this.state.isPublic })
    getMsgsAsync({ isPublic: this.state.isPublic, page: this.state.current, pageSize }).then(
      () => {this.setState({ loading: false }) }
    )
  }

  onChange = e => {
    this.setState({ loading: true })
    let { getMsgsAsync, pageSize } = this.props
    getMsgsAsync({ isPublic: this.state.isPublic, page: e, pageSize }).then(
      () => {this.setState({ loading: false, current: e }) }
    )
  }

  handleSumbit = () => {
    if (!this.state.value) {
      message.warning('请输入留言内容')
      return
    }
    if (!this.state.username) {
      message.warning('请输入昵称')
      return
    }
    this.setState({ submitting: true })
    let BMap = window.BMap//取出window中的BMap对象
    let myCity = new BMap.LocalCity()
    let { value, username, isPublic } = this.state
    let { leaveMsgAsync, getMsgsAsync, pageSize } = this.props
    myCity.get(function (result) {
      let city = result.name
      leaveMsgAsync({
        userId: 3,
        username: username,
        city: city,
        content: value,
        likeNum: 0,
        dislike: 0,
        time: formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
        isPublic: true
      }).then(
        () => {
          message.success('留言成功')
          getMsgsAsync({ isPublic, page: 1, pageSize })//用于刷新留言板内容
        }, () => {
          message.warning('留言失败，请重新尝试')
        }
      )
    })
    this.setState({ submitting: false, value: '', email: '', username: '', current: 1 })
  }

  handleChangeValue = e => {
    this.setState({ value: e.target.value })
  }

  handleChangeUsername = e => {
    this.setState({ username: e.target.value })
  }

  handleLikeMsg = msgId => {
    alert('抱歉，该功能暂不开放！')
    // if (!this.state.isDislike && !this.state.isLike) {
    //   let { getMsgTotalAsync, getMsgsAsync, pageSize } = this.props
    //   let { isPublic, current } = this.state
    //   this.props.likeMsgAsync(msgId).then(() => {
    //     message.success('点赞成功')
    //     getMsgTotalAsync({ isPublic: isPublic })
    //     getMsgsAsync({ isPublic: isPublic, page: current, pageSize })
    //     this.setState({ isLike: true, likeColor: '#eb2f96' })
    //   })
    // } else if (!this.state.isDislike) {
    //   message.warning('您已经点赞过啦~不可以重复点赞噢')
    // } else {
    //   message.warning('您已经不喜欢这条留言了噢，请先取消不喜欢~')
    // }
  }

  handleLikeOver = () => {
    if (this.state.isLike === false) {
      // this.setState({ likeColor: '#eb2f96' })
    }
  }

  handleLikeLeave = () => {
    if (this.state.isLike === false) {
      // this.setState({ likeColor: '#000000' })
    }
  }

  handleDislikeMsg = msgId => {
    alert('抱歉，该功能暂不开放！')
  }

  handleCite = e => {
    let { username, content } = { ...e }
    if (content[0] === '<') {
      for (let i = 0; i < content.length; i++) {
        if (content[i] === '<' && content[i + 1] === '/' && content[i + 2] === '>') {
          content = content.substr(i + 3)
          break
        }
      }
    }
    this.setState({
      // eslint-disable-next-line
      value: `<blockquote>引用<us>${username}</us>的发言：<br/>${content}</blockquote>` + '</>' + this.state.value
    })
  }

  render () {
    const { submitting, value, username, email, loading, current, likeColor } = this.state
    const { msgTotal, pageSize, hideOnSinglePage, showQuickJumper } = this.props
    const { msgs, total } = this.props.msgs
    return (
      <div className={styles.container}>
        <div className={styles.leave_message}>
          <Comment
            avatar={
              <Avatar
                src={head1}
                alt="Han Solo"
              />
            }
            content={
              <Editor
                onChangeValue={this.handleChangeValue}
                onChangeUsername={this.handleChangeUsername}
                onChangeEmail={this.handleChangeEmail}
                onSubmit={this.handleSumbit}
                submitting={submitting}
                value={value}
                username={username}
                email={email}
              />
            }/>
        </div>
        <Divider orientation="right" type="horizontal">留言({msgTotal}条)</Divider>
        <div className={styles.visit_message}>
          <List
            locale={{ emptyText: '/(ㄒoㄒ)/~~目前还没有评论噢~快来评论叭！' }}
            loading={loading}
            itemLayout="horizontal"
            dataSource={msgs}
            pagination={{
              pageSize: pageSize,
              hideOnSinglePage: hideOnSinglePage,
              showQuickJumper: showQuickJumper,
              total: total,
              current: current,
              onChange: this.onChange
            }}
            renderItem={item => (
              <List.Item style={{
                display: 'block',
                width: '95%'
              }}>
                <Comment
                  actions={
                    [
                      <div>
                        <span className={styles.likeOutlined}
                              onMouseOver={() => this.handleLikeOver()}
                              onMouseLeave={() => this.handleLikeLeave()}
                              onClick={() => this.handleLikeMsg({ msgId: item.msgId })}
                        >
                         <LikeTwoTone twoToneColor={likeColor}/></span>
                        <span className={styles.like}>{item.likeNum}</span>
                        <DislikeOutlined onClick={() => this.handleDislikeMsg({ msgId: item.msgId })}/><span
                        className={styles.dislike}>{item.dislikeNum}</span>
                        <span className={styles.cite}
                              onClick={() => this.handleCite({ username: item.username, content: item.content })}>
                          引用
                        </span>
                      </div>
                    ]
                  }
                  author={<ReactMarkdownComponent content={`[` + item.city + `]` + item.username}/>}
                  avatar={head2}
                  content={<ReactMarkdownComponent content={item.content}/>}
                  datetime={item.time === undefined ? item.time : item.time.replace('T', ' ').replace('.000+00:00', '')}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    )
  }
}

MessageCommonComponent.propTypes = {
  hideOnSinglePage: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  showQuickJumper: PropTypes.number
}

MessageCommonComponent = connect(
  ({ $MsgState: { msgs, msgTotal } }) => ({ msgs, msgTotal }),
  { getMsgsAsync, getMsgTotalAsync, leaveMsgAsync, likeMsgAsync }
)(MessageCommonComponent)

export default MessageCommonComponent