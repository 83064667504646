import styles from './NavMenu.module.css'
import React, { Component } from 'react'
import { Button, Input, Menu, Space } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import img_logo from '../../../assets/logo/rz_logo.png'
import { connect } from 'react-redux'
import { jumpPage } from '../function/window'

class NavMenu extends Component {
  state = {
    selectedKey: '',
  }

  handleSearch = e => {
    window.open(`/search?keyword=${e}`)
  }

  componentDidMount () {
    let path = window.location.pathname
    if (path.substr(0, 5) === '/home') this.setState({ selectedKey: 'home' })
    else if (path.substr(0, 3) === '/md') this.setState({ selectedKey: null })
    else if (path.substr(0, 5) === '/mood') this.setState({ selectedKey: 'mood' })
    else if (path.substr(0, 7) === '/memory') this.setState({ selectedKey: 'memory' })
    else if (path.substr(0, 5) === '/tech') this.setState({ selectedKey: 'tech' })
    else if (path.substr(0, 4) === '/msg') this.setState({ selectedKey: 'msg' })
    else if (path.substr(0, 3) === '/me') this.setState({ selectedKey: 'me' })
    else if (path.substr(0, 5) === '/blog') {
      if (this.props.blogContent.category === 'MEMORY') this.setState({ selectedKey: 'memory' })
      else if (this.props.blogContent.category === 'TECH') this.setState({ selectedKey: 'tech' })
      else if (this.props.blogContent.category === 'ME') this.setState({ selectedKey: 'me' })
      else this.setState({ selectedKey: null })
    } else this.setState({ selectedKey: null })
  }

  handleChangeSelect = e => {
    let { key } = e
    this.setState({ selectedKey: key })
  }

  handleSubscribe = () => {
    alert("该功能正在完善中~")
  }

  render () {
    if (this.state.selectedKey === '') {
      this.setState({ selectedKey: 'home' })
    }
    let { accessToken, role } = this.props

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <span onClick={() => {this.setState({ selectedKey: 'home' })}}>
            <Link key="home" to={'/home'}>
              <img src={img_logo} style={{
                height: 30,
                margin: '6px 75px 10px 0px',
              }} alt="logo"/>
            </Link>
          </span>
            <Menu
              selectedKeys={[this.state.selectedKey]}
              mode="horizontal"
              theme="dark"
              onSelect={this.handleChangeSelect}
            >
              {
                this.props.leftList.map(item => (
                  <Menu.Item key={item.key} disabled={item.disabled}>
                    <Link className={styles.block} key={item.key} to={item.link}>
                      {item.name}
                    </Link>
                  </Menu.Item>
                ))
              }
            </Menu>
          </div>
          <div className={styles.right}>
            <Space size='middle'>
              <Input.Search placeholder={'文章标题、内容'}
                            onSearch={this.handleSearch}
                            style={{ width: 200 }}/>
              {
                accessToken && role === 'ROOT' &&
                <Button type="primary" danger onClick={() => jumpPage('/md')}>创作中心</Button>
              }
              {/*{*/}
              {/*  !accessToken &&*/}
              {/*  <Button type="primary" onClick={() => jumpPage('/login')}>登录</Button>*/}
              {/*}*/}
              {
                <Button type="primary" onClick={() => this.handleSubscribe()}>订阅</Button>
              }
            </Space>
          </div>
        </div>
      </div>
    )
  }
}

NavMenu.propTypes = {
  leftList: PropTypes.array,
}

NavMenu = connect(
  ({
    $LoginState: { accessToken, role },
    $BlogState: { blogContent }
  }) => ({ blogContent, accessToken, role })
)(NavMenu)

export default NavMenu
