import { initBlogState, initDataState, initMoodState, initMsgState } from './initState'
import {
  EDIT_MARKDOWN,
  LOGIN,
  LOGIN_STATE,
  SET_BLOG_CONTENT, SET_BLOG_LIKE, SET_BLOG_SEARCH,
  SET_BLOGS, SET_COMMENTS,
  SET_CURRENT_BLOGS, SET_MOOD_TOTAL,
  SET_MOODS, SET_MSG_LIKE, SET_MSG_TOTAL,
  SET_MSGS, SET_UV, UPDATE_MSG_NUM
} from './action-types'
import { get_user_state, remember_user_state, save_user_state } from '../global'

export function $GlobalState (state = {}, { data, type }) {
  switch (type) {
    default:
      return state
  }
}

/**
 * 定义登陆状态的reducer
 * @param state
 * @param data
 * @param type
 */
export function $LoginState (state = { accessToken: '', role: '' }, { data, type }) {
  switch (type) {
    case LOGIN:
      save_user_state({ ...data })
      remember_user_state({ ...data })
      return { ...state, ...data }
    case LOGIN_STATE:
      return get_user_state()
    default:
      return state
  }
}

/**
 * 保存Blog相关状态
 * @param state
 * @param data
 * @param type
 * @returns {{content: string}|{content: *}}
 */
export function $BlogState (state = initBlogState, { data, type }) {
  switch (type) {
    case EDIT_MARKDOWN:
      return { ...state, mdContent: data }
    case SET_BLOGS:
      return { ...state, blogs: data }
    case SET_CURRENT_BLOGS:
      return { ...state, currentBlogs: data }
    case SET_BLOG_CONTENT:
      return { ...state, blogContent: data }
    case SET_BLOG_LIKE: {
      let { blogContent } = state
      blogContent.likeNum = data
      return { ...state, blogContent: { ...state.blogContent, ...blogContent } }
    }
    case SET_COMMENTS:
      return { ...state, comments: data }
    case UPDATE_MSG_NUM: {
      let { blogContent } = state
      blogContent.msgNum = data.msgNum
      return { ...state, blogContent: { ...state.blogContent, ...blogContent } }
    }
    case SET_BLOG_SEARCH:
      return { ...state, searchBlogs: data }
    default:
      return state
  }
}

/**
 * 留言相关状态
 * @param state
 * @param data
 * @param type
 * @returns {{msgs: []}|{msgs: *}}
 */
export function $MsgState (state = initMsgState, { data, type }) {
  switch (type) {
    case SET_MSGS:
      return { ...state, msgs: data }
    case SET_MSG_TOTAL:
      return { ...state, msgTotal: data }
    case SET_MSG_LIKE: {
      let { msgs } = state
      for (let i = 0; i < msgs.length; i++) {
        if (msgs[i].msgId === data.msgId) {
          msgs[i].likeNum = data.likeNum
        }
      }
      return { ...state, msgs: { ...state.msgs, ...msgs } }
    }
    default:
      return state
  }
}

/**
 * 心情随笔相关状态
 * @param state
 * @param data
 * @param type
 * @returns {{moods: *}|{moods: []}}
 */
export function $MoodState (state = initMoodState, { data, type }) {
  switch (type) {
    case SET_MOODS:
      return { ...state, moods: data }
    case SET_MOOD_TOTAL:
      return { ...state, moodTotal: data }
    default:
      return state
  }
}

export function $DataState (state = initDataState, { data, type }) {
  switch (type) {
    case SET_UV:
      return { ...state, uv: data }
    default:
      return state
  }
}
