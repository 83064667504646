import React, { Component } from 'react'
import propTypes from 'prop-types'

class InfoBlockComponent extends Component {
  render () {
    let { title, content, borderColor, backgroundColor } = this.props
    return (
      <div style={{
        width: '100%',
        height: '49.5%',
        border: 'inset',
        borderColor: borderColor,
        borderWidth: '3px 3px 3px 0',
        padding: '12px 16px',
        backgroundColor: backgroundColor,
      }}>
        <header style={{ fontSize: '20px', fontWeight: 'bold' }}>{title}</header>
        <div style={{ marginTop: '8px' }}>{content}</div>
      </div>
    )
  }
}

InfoBlockComponent.propTypes = {
  title: propTypes.string.isRequired,
  content: propTypes.string.isRequired,
  borderColor: propTypes.string.isRequired,
  backgroundColor: propTypes.string.isRequired,
}

export default InfoBlockComponent