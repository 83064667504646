import React, { Component } from 'react'
import styles from './TechPage.module.css'
import InformationBarComponent from '../component/home-component/InformationBarComponent'
import TechLeftComponent from '../component/tech-component/TechLeftComponent'

class TechPage extends Component {

  componentDidMount () {
    document.title = 'RZBLOG-技术分享'
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <TechLeftComponent/>
        </div>
        <div className={styles.right}>
          <InformationBarComponent/>
        </div>
      </div>
    )
  }
}

export default TechPage