import React, { Component } from 'react'
import styles from './MsgListComponent.module.css'
import MessageCommonComponent from './MessageCommonComponent'

class MsgListComponent extends Component {

  render () {
    return (
      <div className={styles.container}>
        <MessageCommonComponent hideOnSinglePage={true} pageSize={15} showQuickJumper={false}/>
      </div>
    )
  }
}

export default MsgListComponent