import React, { Component } from 'react'
import styles from './SearchBlogListComponent.module.css'
import PropTypes from 'prop-types'
import { Divider, List } from 'antd'
import { TagTwoTone, ClockCircleTwoTone, MessageOutlined, EyeOutlined, LikeOutlined } from '@ant-design/icons'
import { changePage, jumpPage } from '../../base/function/window'
import { connect } from 'react-redux'
import { searchAsync } from '../../../redux/async-actions'

const category = {
  'MEMORY': '追忆时光',
  'TECH': '技术分享',
  'ME': '关于”我“',
}

class SearchBlogListComponent extends Component {
  state = {
    loading: true,
    current: 1,
  }

  componentDidMount () {
    let { keyword, searchBlogsAsync, pageSize } = this.props
    searchBlogsAsync({
      keyword,
      type: 'BLOG',
      page: this.state.current,
      pageSize
    }).then(() => {
      this.setState({ loading: false })
    })
  }

  onChange = e => {
    this.setState({ loading: true })
    let { keyword, searchBlogsAsync, pageSize } = this.props
    searchBlogsAsync({
      keyword,
      type: 'BLOG',
      page: e,
      pageSize
    }).then(() => {
      this.setState({ loading: false, current: e })
    })
  }

  render () {
    let { searchBlogs, pageSize, hideOnSinglePage, showQuickJumper } = this.props
    let { loading, current } = this.state
    let { total, list } = searchBlogs
    return (
      <div className={styles.container}>
        <div className={styles.divider}>
          <Divider orientation="left" type="horizontal">搜索结果({total}条)</Divider>
        </div>
        <List
          locale={{ emptyText: '/(ㄒoㄒ)/~~什么都没有搜到，换个关键词试试吧' }}
          loading={loading}
          itemLayout="horizontal"
          dataSource={list}
          pagination={{
            pageSize: pageSize,
            hideOnSinglePage: hideOnSinglePage,
            showQuickJumper: showQuickJumper,
            total: total,
            current: current,
            onChange: this.onChange
          }}
          renderItem={item => (
            <List.Item>
              <div className={styles.article_card}>
                <div className={styles.left_img}>
                  <img src={item.imgUrl} height="144px" width="256px" alt="randyzhangの博客"/>
                </div>
                <div className={styles.right_content}>
                  <div className={styles.article_title}
                       onClick={() => jumpPage(`/blog/${item.blogId}`)}
                       dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <div className={styles.article_content}>
                    <span dangerouslySetInnerHTML={{
                      __html: (item.content.length > 0 ? item.content.replace(/#/g, '').replace(/\*/g, '').replace(/`/g, '') : item.content)
                    }}/>
                    <span>...</span>
                    <span onClick={() => jumpPage(`/blog/${item.blogId}`)}
                          className={styles.article_detail}>
                      [详情]
                    </span>
                  </div>
                  <div className={styles.article_other}>
                    <div className={styles.other_left}>
                      <TagTwoTone twoToneColor="#eb2f96"/>
                      <span className={styles.tag}
                            onClick={() => changePage(item.category === 'TECH' ? '/tech' : item.category === 'MEMORY' ? '/memory' : '/me')}>{category[item.category]}</span>
                      <ClockCircleTwoTone twoToneColor="#7ec1ff"/>
                      <span className={styles.time}>{item.releaseTime}</span>
                    </div>
                    <div className={styles.other_right}>
                      <MessageOutlined/><span className={styles.msg}>{item.msgNum}</span>
                      <EyeOutlined/><span className={styles.eye}>{item.eyeNum}</span>
                      <LikeOutlined/><span className={styles.like}>{item.likeNum}</span>
                    </div>
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
    )
  }
}

SearchBlogListComponent.propTypes = {
  hideOnSinglePage: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  showQuickJumper: PropTypes.number,
  keyword: PropTypes.bool.isRequired
}

SearchBlogListComponent = connect(
  ({ $BlogState: { searchBlogs } }) => ({ searchBlogs }),
  { searchBlogsAsync: searchAsync }
)(SearchBlogListComponent)

export default SearchBlogListComponent
