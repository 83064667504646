import React, { Component } from 'react'
import styles from './SubscribeMeComponent.module.css'
import gitee from '../../../assets/other/gitee.png'
import zhihu from '../../../assets/other/zhihu.png'
import csdn from '../../../assets/other/csdn.png'
import github from '../../../assets/other/github.png'

const blockList = [
  {
    logo: gitee,
    name: 'Gitee',
    url: 'https://gitee.com/randy-zhang',
  },
  {
    logo: github,
    name: 'Github',
    url: 'https://github.com/LemonadeRandy',
  },
  {
    logo: zhihu,
    name: '知乎',
    url: 'https://www.zhihu.com/people/zhang-yifu-12',
  },
  {
    logo: csdn,
    name: 'CSDN',
    url: 'https://blog.csdn.net/weixin_44589651?spm=1008.2028.3001.5343'
  },
]

class SubscribeMeComponent extends Component {

  handleJump = url => {
    window.open(url)
  }

  render () {
    return (
      <div className={styles.container}>
        {
          blockList.map(item => (
            <div className={styles.block} onClick={() => this.handleJump(item.url)}>
              <img className={styles.logo} src={item.logo} alt={item.name} width="100%"/>
              <div className={styles.name}>
                {item.name}
              </div>
            </div>
          ))
        }
      </div>
    )
  }

}

export default SubscribeMeComponent