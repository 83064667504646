import React from 'react'
import { Switch as SwitchRouter, Route, Redirect } from 'react-router-dom'
import TestPage from './components/page/TestPage'
import HomePage from './components/page/HomePage'
import { BaseLayout } from './components/base/layout/BaseLayout'
import HomeLayout from './components/base/layout/HomeLayout'
import MoodPage from './components/page/MoodPage'
import MemoryPage from './components/page/MemoryPage'
import TechPage from './components/page/TechPage'
import AboutMePage from './components/page/AboutMePage'
import MsgPage from './components/page/MsgPage'
import MarkdownPage from './components/page/MarkdownPage'
import ArticleDetailedPage from './components/page/ArticleDetailedPage'
import UploadComponent from './components/component/upload-component/UploadComponent'
import SearchPage from './components/page/SearchPage'
import LoginPage from './components/page/login/LoginPage'
import RegisterPage from './components/page/register/RegisterPage'
class App extends React.Component {

  render () {

    // if (true) {
    //   return (
    //     <SwitchRouter>
    //       <Route path="/maintain" component={MaintainPage}/>
    //       <Redirect to={`/maintain`}/>
    //     </SwitchRouter>
    //   )
    // }

    return (
      <BaseLayout>
        <HomeLayout>
          <SwitchRouter>
            <Route path="/home" component={HomePage}/>
            <Route path="/mood" component={MoodPage}/>
            <Route path="/memory" component={MemoryPage}/>
            <Route path="/tech" component={TechPage}/>
            <Route path="/msg" component={MsgPage}/>
            <Route path="/me" component={AboutMePage}/>
            <Route path="/test" component={TestPage}/>
            <Route path="/md" component={MarkdownPage}/>
            <Route path="/search" component={SearchPage}/>
            <Route path="/blog/:blogId" component={ArticleDetailedPage}/>
            <Route path="/upload" component={UploadComponent}/>
            <Route path="/login" component={LoginPage}/>
            <Route path="/register" component={RegisterPage}/>
            <Redirect to={`/home`}/>
          </SwitchRouter>
        </HomeLayout>
      </BaseLayout>
    )
  }
}

export default App
