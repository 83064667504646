import React, { Component } from 'react'
import CarouselTopComponent from './CarouselTopComponent'
import styles from './HomeLeftComponent.module.css'
import InfoBlockComponent from './InfoBlockComponent'
import LineIntroductionComponent from '../common-component/LineIntroductionComponent'
import ArticleListComponent from '../article-component/ArticleListComponent'

const props_infoBlock_top = {
  title: '关于“我”',
  content: '00后，安徽芜湖人，本科在西安，硕士在北京',
  borderColor: 'rgb(239,165,202)',
  backgroundColor: 'rgb(239,165,202,0.2)',
}

const props_infoBlock_bottom = {
  title: '关于该站',
  content: '闲暇之余，兴趣爱好，记录时光，记录自己',
  borderColor: 'rgb(19,163,202)',
  backgroundColor: 'rgb(19,163,202,0.2)',
}

class HomeLeftComponent extends Component {

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.top_content}>
          <div className={styles.carousel}>
            <CarouselTopComponent/>
          </div>
          <div className={styles.infoBlock}>
            <InfoBlockComponent {...props_infoBlock_top}/>
            <InfoBlockComponent {...props_infoBlock_bottom}/>
          </div>
        </div>
        <div className={styles.main_content}>
          <div className={styles.line_introduction}>
            <LineIntroductionComponent title="最近文章" borderColor="#FF6347"/>
          </div>
          <div className={styles.articleList}>
            <ArticleListComponent pageSize={8} hideOnSinglePage={true} category={'undefined'}/>
          </div>
        </div>
      </div>
    )
  }
}

export default HomeLeftComponent