import React, { Component } from 'react'
import { Button, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

class UploadComponent extends Component {

  render () {
    const uploadProps = {
      name: 'file',
      action: '/api/file',
      method: 'POST',
      // header:
      // data:
      onChange (info) {
        // console.log(info).file
        if (info.file.status !== 'uploading') {
          let { response } = info.file
          if (info.file.status === 'done') {
            message.success('上传成功')
          } else if (info.file.status === 'error') {
            message.error(response || '上传失败')
          }
        }
      },
      showUploadList: {
        showRemoveIcon: false
      }
    }
    return (
      <div>
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined/>}>Upload</Button>
        </Upload>
      </div>
    )
  }

}

export default UploadComponent