import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ArticleContentComponent.module.css'
import {
  CalendarTwoTone,
  EyeOutlined,
  UserOutlined
} from '@ant-design/icons'
import { message } from 'antd'
import LeaveCommentComponent from './LeaveCommentComponent'
import { connect } from 'react-redux'
import { getCommentsAsync } from '../../../redux/async-actions'
import ReactMarkdownComponent from './ReactMarkdownComponent'

class ArticleContentComponent extends Component {

  state = {
    loading: true,
  }

  componentDidMount () {
    let { blogId } = this.props
    this.props.getCommentsAsync({ isPublic: 1, blogId: blogId }).then(
      () => {this.setState({ loading: false }) }
    )
  }

  handleLike = () => {
    if (this.state.islike === 0) {
      let { likeBlogAsync, content } = this.props
      this.setState({ islike: 1 })
      likeBlogAsync({ blogId: content.blogId }).then(
        () => {
          message.success('点赞成功，谢谢你呀~')
          this.setState({ likeColor: '#eb2f96' })
        },
        () => {message.warning('点赞失败，等一会再尝试叭~')}
      )
    } else {
      message.warning('不要重复点赞噢~')
    }
  }

  handleLikeOver = () => {
    if (this.state.islike === 0) {
      this.setState({ likeColor: '#eb2f96' })
    }
  }

  handleLikeLeave = () => {
    if (this.state.islike === 0) {
      this.setState({ likeColor: '#000000' })
    }
  }

  handleShow = () => {
    this.setState({ isShow: !this.state.isShow })
  }

  render () {
    let { blogId, title, author, releaseTime, content, eyeNum, likeNum, msgNum } = this.props.content
    let { comments } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.article_info}>
          <span className={styles.author}>
            <UserOutlined/>
            <span className={styles.author_content}>
              {author || '匿名'}
            </span>
          </span>
          <span className={styles.date}>
              <CalendarTwoTone/>
          <span className={styles.date_content}>
            {releaseTime}
          </span>
          </span>
          <span className={styles.eye}>
            <EyeOutlined/>
            <span className={styles.eye_content}>
              {eyeNum}
            </span>
          </span>
        </div>
        <ReactMarkdownComponent content={content}/>
        <div className={styles.copyright_box}>
          <div className={styles.copyright_content}>
            <div>版权声明：本站原创文章，于{releaseTime}，由<a
              href={`https://www.randyzhang.com`}>{author}</a>发表
            </div>
            <div>转载请注明：<a href={`https://www.randyzhang.com/blog/${blogId}`}>{title}</a> | <a
              href={`https://www.randyzhang.com`}>randyzhangの博客</a></div>
          </div>
        </div>
        <div className={styles.leaveComment}>
          <LeaveCommentComponent blogId={blogId} msgList={comments} msgNum={msgNum} likeNum={likeNum}/>
        </div>
      </div>
    )
  }
}

ArticleContentComponent.propTypes = {
  content: PropTypes.any.isRequired,
  blogId: PropTypes.number.isRequired,
}

ArticleContentComponent = connect(
  ({ $BlogState: { comments } }) => ({ comments }),
  { getCommentsAsync }
)(ArticleContentComponent)

export default ArticleContentComponent