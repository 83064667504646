import React, { Component } from 'react'
import LineIntroductionComponent from '../common-component/LineIntroductionComponent'
import styles from './TechLeftComponent.module.css'
import ArticleListComponent from '../article-component/ArticleListComponent'

class TechLeftComponent extends Component {

  render () {

    return (
      <div className={styles.container}>
        <div className={styles.line_introduction}>
          <LineIntroductionComponent title="技术分享" borderColor="#FF6347"/>
        </div>
        <div className={styles.memory_list}>
          <ArticleListComponent hideOnSinglePage={false} pageSize={15}
                                showQuickJumper={false} category={'TECH'}/>
        </div>
      </div>
    )
  }
}

export default TechLeftComponent