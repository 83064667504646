import React, { Component } from 'react'
import styles from './MoodPage.module.css'
import InformationBarComponent from '../component/home-component/InformationBarComponent'
import MoodLeftComponent from '../component/mood-component/MoodLeftComponent'

class MoodPage extends Component {

  componentDidMount () {
    document.title = 'RZBLOG-心情随笔'
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <MoodLeftComponent/>
        </div>
        <div className={styles.right}>
          <InformationBarComponent/>
        </div>
      </div>
    )
  }
}

export default MoodPage