import React, { Component } from 'react'
import styles from './ArticleDetailedPage.module.css'
import InformationBarComponent from '../component/home-component/InformationBarComponent'
import ArticleLeftComponent from '../component/article-component/ArticleLeftComponent'
import { connect } from 'react-redux'
import { getBlogContentAsync } from '../../redux/async-actions'

class ArticleDetailedPage extends Component {

  async componentDidMount () {
    await this.props.getBlogContentAsync({ blogId: Number(this.props.match.params.blogId) })
    let { title } = this.props.blogContent
    document.title = 'RZBLOG-' + title
  }

  render () {
    let { blogContent } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <ArticleLeftComponent blogContent={blogContent} blogId={Number(this.props.match.params.blogId)}/>
        </div>
        <div className={styles.right}>
          <InformationBarComponent/>
        </div>
      </div>
    )
  }
}

ArticleDetailedPage = connect(
  ({ $BlogState: { blogContent } }) => ({ blogContent }),
  { getBlogContentAsync }
)(ArticleDetailedPage)

export default ArticleDetailedPage