import React, { Component } from 'react'
import { Button, Comment, Divider, Form, Input, List, message } from 'antd'
import { connect } from 'react-redux'
import { getCommentsAsync, likeBlogAsync, releaseCommentAsync } from '../../../redux/async-actions'
import { formatDate } from '../../base/function/formate'
import PropTypes from 'prop-types'
import styles from './LeaveCommentComponent.module.css'
import { DislikeOutlined, LikeOutlined, LikeTwoTone, MessageTwoTone } from '@ant-design/icons'
import { isDevelop } from '../../base/function/prompt'
import ReactMarkdownComponent from './ReactMarkdownComponent'
import head2 from '../../../assets/img/head3.jpg'

const Editor = ({ onChangeValue, onChangeUsername, onSubmit, submitting, value, username }) => (
  <>
    <Form.Item>
      <Input.TextArea rows={4} onChange={onChangeValue} value={value}
                      maxLength={255} showCount allowClear
                      placeholder="请输入评论内容，不超过255字，换行需空一行"/>
    </Form.Item>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}>
      <Form.Item label="昵称" required style={{ width: '40%' }}>
        <Input value={username} maxLength={20} placeholder="请输入您要留下的昵称" onChange={onChangeUsername}/>
      </Form.Item>
      {/*<Form.Item label="邮箱" style={{ width: '50%' }}>*/}
      {/*  <Input value={email} maxLength={50} placeholder="邮箱不会被公开" onChange={onChangeEmail}/>*/}
      {/*</Form.Item>*/}
    </div>
    <Form.Item style={{
      float: 'right'
    }}>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        评论一下
      </Button>
    </Form.Item>
  </>
)

class LeaveCommentComponent extends Component {
  state = {
    islike: 0,
    isShow: false,
    submitting: false,
    value: '',
    username: '',
    likeColor: '#bfbfbf',
    commentColor: '#bfbfbf',
  }

  handleSumbit = () => {
    if (!this.state.value) {
      message.warning('请输入留言内容')
      return
    }
    if (!this.state.username) {
      message.warning('请输入昵称')
      return
    }
    this.setState({ submitting: true })
    let BMap = window.BMap//取出window中的BMap对象
    let myCity = new BMap.LocalCity()
    let { value, username } = this.state
    let { releaseCommentAsync, getCommentsAsync, blogId } = this.props
    myCity.get(function (result) {
      let city = result.name
      releaseCommentAsync({
        userId: 3,
        username: username,
        city: city,
        content: value,
        likeNum: 0,
        dislike: 0,
        time: formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
        isPublic: true,
        blogId: blogId
      }).then(
        () => {
          message.success('评论成功')
          getCommentsAsync({ isPublic: 1, blogId: blogId })
        }, () => {
          message.warning('评论失败，请重新尝试')
        }
      )
    })
    this.setState({ submitting: false, value: '', username: '' })
  }

  handleChangeValue = e => {
    this.setState({ value: e.target.value })
  }

  handleChangeUsername = e => {
    this.setState({ username: e.target.value })
  }

  handleCite = e => {
    let { username, content } = { ...e }
    if (content[0] === '<') {
      for (let i = 0; i < content.length; i++) {
        if (content[i] === '<' && content[i + 1] === '/' && content[i + 2] === '>') {
          content = content.substr(i + 3)
          break
        }
      }
    }
    this.setState({
      // eslint-disable-next-line
      value: `<blockquote>引用<us>${username}</us>的发言：<br/>${content}</blockquote>` + '</>' + this.state.value,
      isShow: true
    })
  }

  handleLike = () => {
    if (this.state.islike === 0) {
      let { likeBlogAsync, blogId } = this.props
      this.setState({ islike: 1 })
      likeBlogAsync({ blogId: blogId }).then(
        () => {
          message.success('点赞成功，谢谢你呀~')
          this.setState({ likeColor: '#eb2f96' })
        },
        () => {message.warning('点赞失败，等一会再尝试叭~')}
      )
    } else {
      message.warning('不要重复点赞噢~')
    }
  }

  handleLikeOver = () => {
    if (this.state.islike === 0) {
      this.setState({ likeColor: '#eb2f96' })
    }
  }

  handleLikeLeave = () => {
    if (this.state.islike === 0) {
      this.setState({ likeColor: '#bfbfbf' })
    }
  }

  handleCommentOver = () => {
    if (this.state.isShow === false) {
      this.setState({ commentColor: '#eb2f96' })
    }
  }

  handleCommentLeave = () => {
    if (this.state.isShow === false) {
      this.setState({ commentColor: '#bfbfbf' })
    }
  }

  handleShow = () => {
    this.setState({ isShow: !this.state.isShow })
    if (this.state.isShow === false) this.setState({ commentColor: '#eb2f96' })
    else this.setState({ commentColor: '#bfbfbf' })
  }

  render () {
    const { submitting, value, username, likeColor, isShow, commentColor } = this.state
    const { loading, msgList, msgNum, likeNum } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.likeAndMsg}>
          <span className={styles.outlined}
                onClick={() => this.handleLike()}
                onMouseOver={() => this.handleLikeOver()}
                onMouseLeave={() => this.handleLikeLeave()}
          >
            <LikeTwoTone twoToneColor={likeColor}/></span>
          <span className={styles.like}>{likeNum}</span>
          <Divider type="vertical"/>
          <span className={styles.outlined} onClick={() => this.handleShow()}
                onMouseOver={() => this.handleCommentOver()}
                onMouseLeave={() => this.handleCommentLeave()}
          >
            <MessageTwoTone twoToneColor={commentColor}/></span>
          <span className={styles.msg}>{msgNum}</span>
        </div>
        <div className={styles.leave_comment}>
          {
            isShow &&
            <Comment
              content={
                <Editor
                  onChangeValue={this.handleChangeValue}
                  onChangeUsername={this.handleChangeUsername}
                  onSubmit={this.handleSumbit}
                  submitting={submitting}
                  value={value}
                  username={username}
                />
              }
            />
          }
        </div>
        <Divider orientation="left">评论区</Divider>
        <div className={styles.visit_comment}>
          <List
            locale={{ emptyText: '/(ㄒoㄒ)/~~目前还没有评论噢~快来评论叭！' }}
            loading={loading}
            itemLayout="horizontal"
            dataSource={msgList}
            renderItem={item => (
              <List.Item style={{
                display: 'block',
                width: '95%'
              }}>
                <Comment
                  actions={
                    [
                      <div>
                        <LikeOutlined onClick={isDevelop}/><span className={styles.like}>{item.likeNum}</span>
                        <DislikeOutlined onClick={isDevelop}/><span className={styles.dislike}>{item.dislikeNum}</span>
                        <span className={styles.cite}
                              onClick={() => this.handleCite({ username: item.username, content: item.content })}>
                          引用
                        </span>
                      </div>
                    ]
                  }
                  author={<ReactMarkdownComponent content={`[` + item.city + `]` + item.username}/>}
                  avatar={head2}
                  content={<ReactMarkdownComponent content={item.content}/>}
                  datetime={item.time === undefined ? item.time : item.time.replace('T', ' ').replace('.000+00:00', '')}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    )
  }
}

LeaveCommentComponent.propTypes = {
  blogId: PropTypes.number.isRequired,
  msgList: PropTypes.array.isRequired,
  likeNum: PropTypes.number.isRequired,
  msgNum: PropTypes.number.isRequired,
}

LeaveCommentComponent = connect(
  // eslint-disable-next-line
  ({}) => ({}),
  { releaseCommentAsync, getCommentsAsync, likeBlogAsync }
)(LeaveCommentComponent)

export default LeaveCommentComponent