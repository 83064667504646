import React, { Component } from 'react'
import { Input, Form, Button, Comment, message } from 'antd'
import { formatDate } from '../../base/function/formate'
import { connect } from 'react-redux'
import { getMoodsAsync, getMoodTotalAsync, leaveMoodAsync } from '../../../redux/async-actions'

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <Input.TextArea rows={4} onChange={onChange} value={value} maxLength={255} showCount allowClear/>
    </Form.Item>
    <Form.Item style={{
      float: 'right'
    }}>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        记录时光
      </Button>
    </Form.Item>
  </>
)

class LeaveMoodComponent extends Component {

  state = {
    submitting: false,
    value: '',
  }

  handleSumbit = () => {
    if (!this.state.value) {
      message.warning('请输入留言内容')
      return
    }
    this.setState({ submitting: true })
    let content = this.state.value
    let { leaveMoodAsync, getMoodsAsync, getMoodTotalAsync } = this.props
    leaveMoodAsync({
      content: content,
      time: formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
    }).then(
      () => {
        message.success('发布成功')
        getMoodsAsync()//用于刷新
        getMoodTotalAsync()
      }, () => {
        message.warning('发布失败，请重新尝试')
      }
    )
    this.setState({ submitting: false, value: '' })
  }

  handleChange = e => {
    this.setState({ value: e.target.value })
  }

  render () {
    const { submitting, value } = this.state
    return (
      <div>
        <Comment
          content={
            <Editor
              onChange={this.handleChange}
              onSubmit={this.handleSumbit}
              submitting={submitting}
              value={value}
            />
          }/>
      </div>
    )
  }
}

LeaveMoodComponent = connect(
  // eslint-disable-next-line
  ({}) => ({}),
  { leaveMoodAsync, getMoodsAsync, getMoodTotalAsync }
)(LeaveMoodComponent)

export default LeaveMoodComponent