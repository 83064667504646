import React, { Component } from 'react'
import styles from './MemoryPage.module.css'
import InformationBarComponent from '../component/home-component/InformationBarComponent'
import MemoryLeftComponent from '../component/memory-component/MemoryLeftComponent'

class MemoryPage extends Component {

  componentDidMount () {
    document.title = 'RZBLOG-追忆时光'
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <MemoryLeftComponent/>
        </div>
        <div className={styles.right}>
          <InformationBarComponent/>
        </div>
      </div>
    )
  }
}

export default MemoryPage