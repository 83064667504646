import React from 'react'
import Footer from '../footer/Footer'

export function BaseLayout (props) {
  return (
    <div style={{
      minWidth: 720,
    }}>
      <div>
        {props.children}
      </div>
      <Footer/>
    </div>
  )
}