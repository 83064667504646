import React, { Component } from 'react'
import { CopyrightOutlined } from '@ant-design/icons'
import styles from './Footer.module.css'
import PropTypes from 'prop-types'
import { Space } from 'antd'
import img_beian from '../../../assets/logo/beianLogo.png'
import { jumpPage } from '../function/window'

export default class Footer extends Component {
  static propTypes = {
    language: PropTypes.object
  }

  render () {
    return (
      <div className={styles.Footer}>
        <div className={styles.body}>
          <Space>
            <span>CopyRight</span>
            <CopyrightOutlined/>
            <span>2021 www.randyzhang.com All rights reserved</span>
          </Space>
        </div>
        <div className={styles.records}>
          <Space>
            <span className={styles.icp}
                  onClick={() => jumpPage('https://beian.miit.gov.cn')}>皖ICP备2021004812号</span>
            <img alt="备案logo" src={img_beian}/>
            <span className={styles.police}
                  onClick={() => jumpPage('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34020702000611')}>
              皖公网安备34020702000611号
            </span>
          </Space>
        </div>
      </div>
    )
  }
}
