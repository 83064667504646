import React, { Component } from 'react'
import { Carousel } from 'antd'
import img1 from '../../../assets/other/carousel_pku.png'
import img2 from '../../../assets/other/carousel_home.png'
import img3 from '../../../assets/other/carousel_jingse1.png'
import img4 from '../../../assets/other/carousel_jingse2.png'
import img5 from '../../../assets/other/carousel_jingse3.png'

class CarouselTopComponent extends Component {
  render () {
    let carouselList = [
      { img: img4, name: '轮播图' },
      { img: img3, name: '轮播图' },
      { img: img5, name: '轮播图' },
      { img: img1, name: '轮播图' },
      { img: img2, name: '轮播图' }
    ]
    return (
      <div>
        <Carousel autoplay>
          {
            carouselList.map(item => (
              <div style={{
                border: '1px'
              }}>
                <img src={item.img} alt={item.name} width="540px" height="304px"/>
              </div>
            ))
          }
        </Carousel>
      </div>
    )
  }
}

export default CarouselTopComponent