import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './WebInfoComponent.module.css'
import { getUVAsync } from '../../../redux/async-actions'
import { formatDate } from '../../base/function/formate'
import { UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'

class WebInfoComponent extends Component {

  componentDidMount () {
    let { getUVAsync } = this.props
    getUVAsync({ startTime: '2022-04-01', endTime: formatDate(new Date(), 'yyyy-MM-dd') })
  }

  render () {
    let { uv } = this.props
    let infoList = [
      {
        icon: <UserOutlined/>,
        type: '今日用户访问量',
        number: uv.uv === undefined ? '' : uv.uv
      },
      {
        icon: <UsergroupAddOutlined/>,
        type: '累计用户访问量',
        number: uv.all === undefined ? '' : uv.all
      }
    ]
    return (
      <div className={styles.container}>
        {
          infoList.map(item => (
            <div className={styles.list}>
              <span className={styles.icon}>{item.icon}</span>
              <div>{item.type}</div>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <div className={styles.data}>{item.number}</div>
            </div>
          ))
        }
      </div>
    )
  }
}

WebInfoComponent = connect(
  ({ $DataState: { uv } }) => ({ uv }),
  { getUVAsync }
)(WebInfoComponent)

export default WebInfoComponent
