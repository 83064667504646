import React, { Component } from 'react'
import styles from './SearchPage.module.css'
import SearchContentComponent from '../component/search-component/SearchContentComponent'
import LineIntroductionComponent from '../component/common-component/LineIntroductionComponent'

class SearchPage extends Component {

  componentDidMount () {
    let keyword = this.props.location.search.split('=')[1]
    document.title = 'RZBLOG-"' + decodeURI(keyword) + '"搜索结果'
  }

  render () {
    let keyword = this.props.location.search.split('=')[1]
    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <LineIntroductionComponent borderColor="#FF6347" title={`搜索关键词"${decodeURI(keyword)}"`}/>
        </div>
        <div className={styles.bottom}>
          <SearchContentComponent keyword={keyword}/>
        </div>
      </div>
    )
  }
}

export default SearchPage
