import store from '../redux/store'
import { get_user_ip } from '../global'

/**
 * post请求构造
 * @param body
 * @returns {Object}
 */
export const postInit = body => ({
  method: 'POST',
  body: JSON.stringify(body),
  headers: headers(),
  mode: 'cors'
})

/**
 * 构造带有AccessToken 的headers
 * @returns {Headers}
 */
export const headers = () => {
  let headers = new Headers()
  let clientIp = get_user_ip()
  // let { accessToken } = store.getState().$LoginState
  // if (accessToken !== '') headers.append('AccessToken', accessToken)
  headers.append('content-type', 'application/json')
  headers.append('client-ip', clientIp)
  return headers
}

export const formHeaders = () => {
  let headers = new Headers()
  let clientIp = get_user_ip()
  // let { accessToken } = store.getState().$LoginState
  // if (accessToken !== '') headers.append('AccessToken', accessToken)
  // headers.append('content-type', 'multipart/form-data')
  headers.append('client-ip', clientIp)
  return headers
}

/**
 * get请求构造
 * @returns {{mode: string, headers: Headers}}
 */
export const getInit = () => ({
  headers: headers(),
  mode: 'cors'
})

export const deleteInit = body => ({
  headers: headers(),
  mode: 'cors',
  method: 'DELETE',
  body: JSON.stringify(body),
})

export const putInit = body => ({
  headers: headers(),
  mode: 'cors',
  method: 'PUT',
  body: JSON.stringify(body),
})

/**
 * 生成url query字符串, 不带问号，需自行添加问号
 * @param {Object} args query参数列表，args为一个object
 * @returns {string}
 */
export const query = (args = {}) => {
  let result = ''
  for (const key in args) if (args.hasOwnProperty(key)) {
    const value = args[key]
    if (result.length !== 0) result += '&'
    result += key + '=' + value
  }
  return result
}

/**
 *
 * @param {string} url
 * @param {Object} init 初始化对象
 * @returns {Promise<JSON>}
 */
export const request = async (url, init = {}) => (await fetch(url, init)).json()

/**
 *
 * @param {string} url
 * @param file
 * @returns {Promise<JSON>}
 */
export const uploadFile = async (url, file) => {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    let data = new FormData()
    data.append('file', file)
    xhr.timeout = 25000
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText)
          resolve(data)
        } else {
          reject('网络请求失败')
        }
      }
    }
    xhr.ontimeout = function () {
      reject('网络请求超时，请稍后再试!')
    }
    xhr.onerror = function () {
      reject('网络连接失败，请检查您的网络！')
    }
    xhr.open('post', url)
    xhr.send(data)
  })
}

export function uploadForm (url, form) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    xhr.timeout = 25000
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText)
          resolve(data)
        } else {
          reject('网络请求失败')
        }
      }
    }
    xhr.ontimeout = function () {
      reject('网络请求超时，请稍后再试!')
    }
    xhr.onerror = function () {
      reject('网络连接失败，请检查您的网络！')
    }
    let { accessToken } = store.getState().$LoginState
    xhr.open('post', url)
    xhr.setRequestHeader('accessToken', accessToken)
    xhr.send(form)
  })
}
