import React, { Component } from 'react'
import styles from './LoginPage.module.css'

class LoginPage extends Component {
  render () {
    return (
      <div className={styles.container}>

      </div>
    )
  }
}

export default LoginPage
