import React, { Component } from 'react'
import styles from './HomePage.module.css'
import HomeLeftComponent from '../component/home-component/HomeLeftComponent'
import InformationBarComponent from '../component/home-component/InformationBarComponent'

class HomePage extends Component {

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <HomeLeftComponent/>
        </div>
        <div className={styles.right}>
          <InformationBarComponent/>
        </div>
      </div>
    )
  }
}

export default HomePage