import React, { Component } from 'react'
import styles from './MemoryLeftComponent.module.css'
import LineIntroductionComponent from '../common-component/LineIntroductionComponent'
import ArticleListComponent from '../article-component/ArticleListComponent'

class MemoryLeftComponent extends Component {

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.line_introduction}>
          <LineIntroductionComponent title="追忆时光" borderColor="#FF6347"/>
        </div>
        <div className={styles.memory_list}>
          <ArticleListComponent hideOnSinglePage={false} pageSize={15}
                                showQuickJumper={false} category={'MEMORY'}/>
        </div>
      </div>
    )
  }
}

export default MemoryLeftComponent