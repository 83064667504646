import { message } from 'antd'

export const success = async msg => {
  await message.success(msg)
}

export const error = async msg => {
  await message.error(msg)
}

export const warning = async msg => {
  await message.warning(msg)
}

/**
 * 异步action 统一处理
 * @param response
 * @param success
 * @param err0
 * @param err1
 * @returns {Promise<unknown>}
 */
export async function asyncResponseHandler (
  response,
  success = () => {},
  err0 = async msg => await error(msg),
  err1 = async msg => await warning(msg)) {
  let { code, msg, data } = response
  if (code === 1) {
    success()
    return Promise.resolve(data)
  } else if (code === 0) {
    await err0(msg)
    return Promise.reject(msg)
  } else if (code === -1) {
    await err1(msg)
    return Promise.reject(msg)
  }

}