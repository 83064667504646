import React, { Component } from 'react'
import styles from './FriendshipLinkComponent.module.css'
import { List } from 'antd'

const linkList = [
  {
    key: 1,
    name: '西北工业大学',
    link: 'http://www.nwpu.edu.cn/',
  },
  {
    key: 2,
    name: '西北工业大学软件学院',
    link: 'http://ruanjian.nwpu.edu.cn/',
  },
  {
    key: 3,
    name: '北京大学',
    link: 'https://www.pku.edu.cn/',
  },
  {
    key: 4,
    name: '北京大学软件与微电子学院',
    link: 'http://www.ss.pku.edu.cn/',
  },
]

class FriendshipLinkComponent extends Component {
  handleJump = link => {
    window.open(link)
  }

  render () {
    return (
      <div className={styles.container}>
        <List
          grid={{ gutter: 16 }}
          dataSource={linkList}
          renderItem={item => (
            <List.Item>
              <span className={styles.name} onClick={() => this.handleJump(item.link)}>{item.name}</span>
            </List.Item>
          )}
        />
      </div>
    )
  }
}

export default FriendshipLinkComponent