import React, { Component } from 'react'
import MdEditorComponent from '../component/md-component/MdEditorComponent'
import { ToolBoxComponent } from '../component/md-component/ToolBoxComponent'
import BlogInfoSettingComponent from '../component/common-component/BlogInfoSettingComponent'
import styles from './MarkdownPage.module.css'

class MarkdownPage extends Component {

  componentDidMount () {
    document.title = 'RZBLOG-Markdown'
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.blogInfo}>
          <BlogInfoSettingComponent/>
        </div>
        <ToolBoxComponent/>
        <MdEditorComponent/>
      </div>
    )
  }
}

export default MarkdownPage