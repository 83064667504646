import React, { Component } from 'react'
import LineIntroductionComponent from '../common-component/LineIntroductionComponent'
import styles from './MsgLeftComponent.module.css'
import MsgListComponent from './MsgListComponent'

class MsgLeftComponent extends Component{

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.line_introduction}>
          <LineIntroductionComponent borderColor="#FF6347" title="访客留言"/>
        </div>
        <div className={styles.msg_list}>
          <MsgListComponent/>
        </div>
      </div>
    )
  }
}

export default MsgLeftComponent