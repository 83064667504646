/**
 * 登陆状态部分
 * @type {string}
 */
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const LOGIN_STATE = 'login_state'

/**
 * 博客部分
 * @type {string}
 */
export const EDIT_MARKDOWN = 'edit_markdown'
export const SET_BLOGS = 'set_blogs'
export const SET_CURRENT_BLOGS = 'set_current_blogs'
export const SET_BLOG_CONTENT = 'set_blog_content'
export const SET_BLOG_LIKE = 'set_blog_like'
export const SET_COMMENTS = 'set_comments'
export const SET_BLOG_SEARCH = 'set_blog_search'
export const UPDATE_MSG_NUM = 'update_msg_num'

/**
 * 留言部分
 * @type {string}
 */
export const SET_MSGS = 'set_msgs'
export const SET_MSG_TOTAL = 'set_msg_total'
export const SET_MSG_LIKE = 'set_msg_like'

/**
 * 心情随笔部分
 * @type {string}
 */
export const SET_MOODS = 'set_moods'
export const SET_MOOD_TOTAL = 'set_mood_total'

/**
 * 数据部分
 * @type {string}
 */
export const SET_UV = 'set_uv'
