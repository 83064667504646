import React, { Component } from 'react'
import styles from './ArticleLeftComponent.module.css'
import LineIntroductionComponent from '../common-component/LineIntroductionComponent'
import ArticleContentComponent from './ArticleContentComponent'
import PropTypes from 'prop-types'

class ArticleLeftComponent extends Component {

  render () {
    let { blogContent, blogId } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.line_introduction}>
          <LineIntroductionComponent borderColor="#FF6347" title="文章详情"/>
        </div>
        <div className={styles.articleContent}>
          <ArticleContentComponent content={blogContent}  blogId={blogId}/>
        </div>
      </div>
    )
  }
}

ArticleLeftComponent.propTypes = {
  blogContent: PropTypes.string.isRequired,
  blogId: PropTypes.number.isRequired,
}

export default ArticleLeftComponent